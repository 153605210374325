import React from 'react';
import Text from '../type/Text';
import Button from './Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ContentItem({
  hideButton,
  image,
  text,
  link,
  title,
  buttontext,
  bestuur,
}) {
  const logo = getImage(image);
  return (
    <div className="flex flex-col items-center flex-nowrap sm:flex-row sm:items-stretch">
      <GatsbyImage
        image={logo}
        objectFit="contain"
        className="relative z-10 object-contain -mb-28 w-60 sm:-mb-0 sm:w-56 md:w-96"
        alt="Logo content item"
      />
      <div
        className={`relative z-0 flex w-full flex-col justify-between bg-brokenwhite p-8 pt-40 sm:-ml-32 sm:pl-40 ${
          bestuur ? 'sm:pt-8' : 'sm:pt-4'
        } md:w-2/3 xl:w-full`}
      >
        <div>
          <h4
            className={`mb-4 font-accent text-xl font-semibold ${
              text ? '' : 'text-center sm:text-left'
            }`}
          >
            {title}
          </h4>
          {text ? <Text className="pb-6 sm:pb-0" text={text} /> : ''}
        </div>
        {hideButton ? (
          ''
        ) : (
          <Button
            url={link}
            nospace={true}
            text={buttontext}
            type={true}
            className="mt-5"
          />
        )}
      </div>
    </div>
  );
}
