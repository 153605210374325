import React from 'react';
import Page from '../components/layouts/Page';
import ImageSlider from '../components/molecules/ImageSlider';
import CallToAction from '../components/CallToAction';
import WoodSpacer from '../components/molecules/WoodSpacer';
import Container from '../components/layouts/Container';
import ContentItem from '../components/molecules/ContentItem';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { graphql } from 'gatsby';

export default function contentCardsCtaSlider({ data }) {
  const content = data.wpPage;
  const innerContent = data.wpPage.paginaTemplate.contentCardsCtaSlider;
  const speltakken = data.allWpSpeltak.nodes;

  const allJsonBreadcrumbs = [];
  const allBreadcrumbs = [];

  if (content.seo.breadcrumbs !== null) {
    content.seo.breadcrumbs.forEach((breadcrumb, index) => {
      allJsonBreadcrumbs.push({
        position: index + 1,
        name: breadcrumb.name,
        item: 'https://graafdaron.nl' + breadcrumb.item,
      });
      allBreadcrumbs.push({
        name: breadcrumb.name,
        item: breadcrumb.item,
      });
    });
  }

  return (
    <>
      {content.seo.breadcrumbs ? (
        <>
          <GatsbySeo
            title={content.seo.seoData.title}
            description={content.seo.seoData.description}
            openGraph={{
              type: 'website',
              url: 'https://graafdaron.nl/' + content.slug,
              title: content.seo.seoData.title,
              description: content.seo.seoData.description,
            }}
          />
          <BreadcrumbJsonLd itemListElements={allJsonBreadcrumbs} />
        </>
      ) : (
        ''
      )}
      <Page
        breadcrumbs={
          <>
            <>
              {allBreadcrumbs.map((breadcrumb) => {
                return (
                  <Breadcrumb title={breadcrumb.name} link={breadcrumb.item} />
                );
              })}
            </>
          </>
        }
        sidebar={false}
        title={content.title}
        html={innerContent.text}
        bottom={
          <>
            <Container className={'pb-8 md:pb-16'}>
              <section className="relative z-20 grid grid-cols-1 gap-x-8 gap-y-6 xl:grid-cols-2 xl:gap-x-16 xl:gap-y-12">
                {innerContent.cards.dynamischeContent ||
                  innerContent.cards.cardsInner.map((card) => {
                    return (
                      <ContentItem
                        image={card.image.localFile}
                        title={card.titleBest}
                        text={card.text}
                        bestuur={innerContent.cards.bestuur}
                        link={card.button.url}
                        buttontext={card.button.title}
                        hideButton={innerContent.cards.bestuur ?? true}
                      />
                    );
                  })}

                {speltakken &&
                  innerContent.cards.dynamischeContent &&
                  speltakken.map((speltak) => {
                    return (
                      <ContentItem
                        image={speltak.speltakken.logo.localFile}
                        text={speltak.speltakken.description}
                        link={`/speltakken/${speltak.slug}`}
                        buttontext="Meer info"
                        hideButton={false}
                      />
                    );
                  })}
              </section>
            </Container>
            {innerContent.callToAction.type === 'customhtml' && (
              <>
                <CallToAction
                  style={innerContent.callToAction.type}
                  html={innerContent.callToAction.customHtml}
                />
                <WoodSpacer small={true} />
              </>
            )}
            {innerContent.callToAction.type !== 'customhtml' && (
              <>
                <ImageSlider />
                <CallToAction
                  style={innerContent.callToAction?.type ?? 'bigtower'}
                  html={innerContent.callToAction?.customHtml}
                  title={innerContent.callToAction?.title}
                  text={innerContent.callToAction?.text}
                  buttontext={innerContent.callToAction.button?.title}
                  buttonurl={innerContent.callToAction.button?.url}
                />
                <WoodSpacer small={true} />
              </>
            )}
          </>
        }
      />
    </>
  );
}

export const query = graphql`
  query contentCardsCtaSlider($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      slug
      paginaTemplate {
        contentCardsCtaSlider {
          text
          callToAction {
            type
            text
            customHtml
            title
            button {
              target
              title
              url
            }
          }
          cards {
            dynamischeContent
            bestuur
            cardsInner {
              titleBest
              button {
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, width: 400)
                  }
                }
              }
              text
            }
          }
        }
      }
      seo {
        seoData {
          description
          title
        }
        breadcrumbs {
          item
          name
        }
      }
    }
    allWpSpeltak(sort: { fields: date, order: ASC }) {
      nodes {
        title
        speltakken {
          description
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 500, quality: 80)
              }
            }
          }
        }
        slug
      }
    }
  }
`;
